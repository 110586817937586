<template>
<div class="page-reporte-lista-precios">
  <v-container>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </v-container>

  <v-container fluid fill-height>
    <v-row :justify="'center'" :align="'center'">
      <v-col sm='12'>
        <base-material-card color="primary" icon="library_books" title="Reporte de Compras" class="elevation-1 px-5 py-3">

          <v-card-text>
            <v-form ref="form" lazy-validation>
              <v-container grid-list-md>
                <v-row>
                  <v-col sm="6" md="6" lg="6">
                    <v-autocomplete v-model="model.id_proveedor" :items="proveedores"
                      chips small-chips deletable-chips
                      hide-no-data hide-selected item-text="nombre" item-value="_id" label="Proveedor"
                      :hide-no-data="true" :hide-selected="true"
                            ></v-autocomplete>
                  </v-col>
                  <v-col sm="3" md="3" lg="3">
                      <v-menu offset-x ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" min-width="290px" offset-y>
                        <template v-slot:activator="{ on }">
                            <v-text-field v-on="on" v-model="model.fecha_de" label="Fecha De" color="secondary" prepend-icon="event"
                            @blur="model.fecha_de = parse_date(model.fecha_de)" :rules="[rules.required, valida_fecha]">
                            </v-text-field>
                        </template>

                        <v-date-picker v-model="model.fecha_de" color="secondary" scrollable locale="es-mx" @input="menu1 = false">
                            <v-spacer />

                            <v-btn color="secondary" x-small @click="menu1 = false">
                            Cancelar
                            </v-btn>
                        </v-date-picker>
                        </v-menu>
                  </v-col>
                  <v-col sm="3" md="3" lg="3">
                      <v-menu offset-x ref="menu2" v-model="menu2" :close-on-content-click="false" transition="scale-transition" min-width="290px" offset-y>
                        <template v-slot:activator="{ on }">
                            <v-text-field v-on="on" v-model="model.fecha_a" label="Fecha A" color="secondary" prepend-icon="event"
                            @blur="model.fecha_a = parse_date(model.fecha_a)" :rules="[rules.required, valida_fecha]">
                            </v-text-field>
                        </template>

                        <v-date-picker v-model="model.fecha_a" color="secondary" scrollable locale="es-mx" @input="menu2 = false">
                            <v-spacer />

                            <v-btn color="secondary" x-small @click="menu2 = false">
                            Cancelar
                            </v-btn>
                        </v-date-picker>
                        </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="2" md="2" lg="2">
                    <v-text-field v-model="model.no_orden" label="# Orden" color="secondary" @input="upperCaseInput('no_orden')">
                    </v-text-field>
                  </v-col>
                  <v-col sm="2" md="2" lg="2">
                    <v-text-field v-model="model.factura" label="# Folio Factura" color="secondary">
                    </v-text-field>
                  </v-col>
                  <v-col sm="2" md="2" lg="2">
                    <v-text-field v-model="model.factura_sat" label="# Folio SAT" color="secondary">
                    </v-text-field>
                  </v-col>                  
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click.native="model = clean_model()">
              <v-icon>cancel</v-icon> Limpiar Filtros
            </v-btn>
            <v-btn color="info" @click.native="consultar()">
                <v-icon>search</v-icon> Consultar
              </v-btn>                    
            <v-btn color="success" @click.native="generar_reporte()">
              <v-icon>done</v-icon> {{ "Generar Reporte" }}
            </v-btn>
          </v-card-actions>

          <v-card-text>
            <v-container>
              <v-client-table ref="vuetable" :data="registros" :columns="columns" :options="options" class="elevation-2 pa-2">
                <template slot="noResults">
                  <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                </template>
                
                <template slot="fecha_recibio_mercancia" slot-scope="props">
                  {{parse_date_time(props.row.fecha_recibio_mercancia)}}
                </template>    
                <template slot="orden_creada" slot-scope="props">
                  {{ (props.row.orden_creada && props.row.orden_creada!="" ? "Si": "No") }}
                </template>                                
                
                <template slot="importe" slot-scope="props">
                    <div style="text-align:right !important;">
                        ${{ formatNumber(parseFloat(props.row.importe).toFixed(2))}}
                    </div>
                </template>

                <template slot="actions" slot-scope="props">                  
                    <v-btn x-small fab dark color="blue" v-show="(props.row.orden_creada && props.row.orden_creada!='')"
                      @click.native="cambiarProveedor(props.row)" title="Editar Proveedor" v-tippy>
                      <v-icon>account_circle</v-icon>
                    </v-btn> &nbsp;                 
                    <v-btn x-small fab dark color="info" 
                      @click.native="verArticulosFactura(props.row)" title="Ver Articulos" v-tippy>
                      <v-icon>list</v-icon>
                    </v-btn>                  
                </template>
              </v-client-table>
            </v-container>
          </v-card-text>


        </base-material-card>
      </v-col>
    </v-row>
  </v-container>

  <v-dialog v-model="modalProveedor" max-width="500px">
        <v-card>
            <v-card-title>
                <v-card class="primary white--text titulomodal">
                    <v-icon class="white--text">edit</v-icon>&nbsp;
                    <span class="subheading">
                        <strong>Editar Proveedor</strong>
                    </span>
                </v-card>
                <v-spacer></v-spacer>
                <v-btn icon @click.native="modalProveedor = false" class="close_modal">
                    <v-icon class="white--text">cancel</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-form ref="formproveedor" lazy-validation>
                    <v-container>
                        <v-row justify="center">
                            <v-col sm="10" md="10" lg="10" style="text-align:center !important;">
                                <v-autocomplete v-model="proveedor_editable" :items="proveedores" :hide-no-data="true" item-text="nombre" item-value="_id" 
                                label="Seleccione Proveedor" :rules="[rules.required]">
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            &nbsp;
                        </v-row>
                        <v-row justify="center">
                            <v-btn small @click.native="modalProveedor = false">
                                <v-icon>cancel</v-icon> Cancelar
                            </v-btn>
                            &nbsp;&nbsp;
                            <v-btn small color="success" @click.native="changeProveedor()">
                                <v-icon>done</v-icon> Aceptar
                            </v-btn>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>

        </v-card>
    </v-dialog>

    <v-dialog v-model="modalArticulosFactura" max-width="95%">
        <v-card>
            <v-card-title>
                <v-card class="primary white--text titulomodal">
                    <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
                    <span class="subheading">
                        <strong>SubOrden: {{model_vista_factura.no_orden}} &nbsp;&nbsp;&nbsp;Factura: {{model_vista_factura.factura_folio}}</strong>
                    </span>
                </v-card>
                <v-spacer></v-spacer>
                <v-btn icon @click.native="modalArticulosFactura = false" class="close_modal">
                    <v-icon class="white--text">cancel</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-container grid-list-md>
                    <v-row>
                        <v-col lg="8" md="8" sm="8">
                            <v-row>
                                <v-col lg="4" md="4" sm="4" v-if="model_vista_factura.proveedor!=null && model_vista_factura.proveedor!=''">
                                    <b>Proveedor:</b> {{model_vista_factura.proveedor.nombre_proveedor}}
                                </v-col>
                                <v-col lg="4" md="4" sm="4">
                                    <b>Sucursal:</b> {{model_vista_factura.nombre_sucursal_destino}}
                                </v-col>
                                <v-col lg="4" md="4" sm="4">
                                    <b>Estatus:</b> {{model_vista_factura.factura_estatus}}
                                </v-col>
                            </v-row><br>
                            <v-row>
                                <v-col lg="6" md="6" sm="6" v-show="model_vista_factura.observaciones_orden!=null && model_vista_factura.observaciones_orden!=''">
                                    <b>Observaciones de Factura:</b><br>
                                    {{model_vista_factura.observaciones_orden}}
                                </v-col>
                                <v-col lg="6" md="6" sm="6" v-show="model_vista_factura.observaciones_entrada!=null && model_vista_factura.observaciones_entrada!=''">
                                    <b>Observaciones de Entrada de Mercancía:</b><br>
                                    {{model_vista_factura.observaciones_entrada}}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col lg="3" md="3" sm="3" v-show="model_vista_factura.factura_descuento!=null && model_vista_factura.factura_descuento!=''">
                                    <b>Descuento Factura:</b><br>
                                    {{model_vista_factura.factura_descuento}} %
                                </v-col>
                            </v-row>
                        </v-col>                        
                    </v-row>
                    
                        
                            
                            <v-client-table ref="vuetablearticulosorden" :data="model_vista_factura.articulos" :columns="columnsArticulosVistaFactura" 
                            :options="optionsArticulosVistaFactura" :key="keyTableArticulosFactura"
                            class="elevation-2 pa-2">
                                <template slot="nombre_articulo" slot-scope="props">
                                    <span style="font-weight:bolder;font-size:10px;"><strong>{{ props.row.nombre_articulo }}</strong></span>
                                </template>
                                <template slot="descripcion" slot-scope="props">
                                    <span style="font-weight:bolder;font-size:10px;"><strong>{{ props.row.descripcion }}</strong></span>
                                </template>
                                <!--template slot="precio_compra" slot-scope="props">
                                    <div style="text-align:right">${{ formatNumberDec(props.row.precio_compra,4) }}</div>
                                </template-->
                                <template slot="precio_sin_iva" slot-scope="props">
                                    ${{formatNumberDec(props.row.precio_sin_iva,2)}}                                    
                                </template>
                                <template slot="iva" slot-scope="props">
                                    <div style="text-align:center">{{ props.row.iva }}%</div>
                                </template>
                                <template slot="precio_con_iva" slot-scope="props">
                                  ${{formatNumberDec(props.row.precio_con_iva,2)}}  
                                </template>
                                <template slot="porcentaje_descuento1" slot-scope="props">
                                  {{props.row.porcentaje_descuento1}}%                                    
                                </template>
                                <template slot="porcentaje_descuento2" slot-scope="props">
                                  {{props.row.porcentaje_descuento2}}%                                    
                                </template>
                                <template slot="precio_factura" slot-scope="props">
                                    <div style="text-align:center">${{ formatNumberDec(props.row.precio_factura,4) }}</div>
                                </template>
                                <template slot="fecha_recibio" slot-scope="props">
                                    {{ parse_date_time_mx(props.row.fecha_recibio)}}
                                </template>
                                <template slot="existencia_articulo" slot-scope="props">
                                    <div style="text-align:center">{{ formatNumberDec(props.row.existencia_articulo,2) }}</div>
                                </template>                             
                                <template slot="cantidad" slot-scope="props">
                                  {{props.row.cantidad}}                                    
                                </template>
                                <template slot="cantidad_recibida" slot-scope="props">
                                    <div style="color:green;text-align:center;"><strong>{{ props.row.cantidad_recibida }}</strong></div>
                                </template>
                                <template slot="cantidad_faltante" slot-scope="props">
                                    {{props.row.cantidad_faltante}}                                    
                                </template>
                                <template slot="cantidad_devuelta" slot-scope="props">
                                  {{props.row.cantidad_devuelta}}                                    
                                </template>
                                <template slot="monto" slot-scope="props">
                                    <div style="text-align:center">${{ getMonto(props.row) }}</div>
                                </template>
                                
                            </v-client-table>
                        </v-col>
                    </v-row>
                </v-container>

            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click.native="modalArticulosFactura=false">
                    <v-icon>cancel</v-icon> Cerrar
                </v-btn>
                
            </v-card-actions>
        </v-card>
    </v-dialog>

</div>
</template>

<script>
export default {
  components: {},
  watch: {
    model_filters: {
      handler(val) {
        this.$nextTick(() => {
          this.items = [];
          this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
        });
      },
      deep: true
    },
    searchProveedor(val) {
      //Si no ha escrito 2 letras o mas
      if (val == null || val.length<2 ) return

      let data = {
                  "selector": {
                      "type":"proveedores",
                      "nombre": {"$regex": "(?i)" + val.toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")},
                      "estatus": "Activo"
                  },
                  "sort": ["nombre"],
              };

          window.axios
              .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',data)
              .then(response => {
                  if (response.data.docs.length > 0){
                      this.proveedores = response.data.docs;
                  }else
                      this.proveedores = [];
              })
              .catch(error => {
                  this.$swal({
                      type: "error",
                      title: "¡Operación no Permitida!",
                      text: "Ocurrió un error al obtener los proveedores.",
                      footer: ""
                  });
              }).then(()=>{});
    },
    search_marca: {
      handler(val) {
        if (val == "to_update") return;
        if (val == "" || val == undefined) {
          return;
        };
        this.getMarcas(val);
      }
    },

  },
  mounted: function() {
    if (!this.verificaPermiso('t.compras.compras')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }
  },
  created: function() {
    this.model = this.clean_model();
    this.model.fecha_de = moment().format("YYYY-MM-DD");
    this.model.fecha_a = moment().format("YYYY-MM-DD");
    this.getProveedores();
  },
  data() {
    return {
      keyTableArticulosFactura:"articulosfactura",
      columnsArticulosVistaFactura: ["nombre_articulo","descripcion",
          //"precio_compra", 
          "precio_sin_iva", "iva", "precio_con_iva",
          "porcentaje_descuento1", "porcentaje_descuento2","precio_factura",
          "monto","cantidad","cantidad_recibida", "fecha_recibio",
      ],
      optionsArticulosVistaFactura: {
          filterable: ["nombre_articulo", "descripcion",
          //"precio_compra",
          "precio_sin_iva", "iva", "precio_con_iva",
          "porcentaje_descuento1", "porcentaje_descuento2",
          "precio_factura", "cantidad_recibida", "fecha_recibio"],
          headings: {
              //"precio_compra":"$ P. Compra S/Imp",              
              "precio_sin_iva":"$ Factura S/Imp",
              "iva":"% Impuestos", "precio_con_iva":"$ Factura C/Imp",
              "porcentaje_descuento1":"%Des1", "porcentaje_descuento2":"%Des2",
              "precio_factura":"Precio Factura",                
              "cantidad_recibida":"Cant. Recibida",              
          },
          rowClassCallback: function (row) {
              return row.resaltado == 1 ? 'bg-resaltado' : 'bg-default'
          },
          /*cellClasses: {
              precio_proveedor: [{
                  class: 'bg-seleccionado',
                  condition: row => row.precio_proveedor != null && row.precio_proveedor != '' && parseFloat(parseFloat(row.precio_proveedor).toFixed(2)) < parseFloat(row.precio_compra_con_iva)
              },
              {
                  class: 'bg-rojo',
                  condition: row => row.precio_proveedor != null && row.precio_proveedor != '' && parseFloat(parseFloat(row.precio_proveedor).toFixed(2)) > parseFloat(row.precio_compra_con_iva)
              }]
          }*/
      },
      model_vista_factura: {
          proveedor:{
              nombre_proveedor:"",
          }
      },
      modalArticulosFactura:false,
      proveedor_editable:"",
      modalProveedor:false,
      model_vista:"",
      columns: [
        "no_orden","orden_creada","fecha_recibio_mercancia","importe","factura_folio","factura_folio_sat","proveedor.nombre_proveedor","actions"
      ],
      options: {
        filterable: ["no_orden","fecha_recibio_mercancia","importe","factura_folio","factura_folio_sat","proveedor.nombre_proveedor"],
        sortable: ["no_orden","orden_creada","fecha_recibio_mercancia","importe","factura_folio","factura_folio_sat","proveedor.nombre_proveedor"],
        pagination: false,         
        headings: {
          "no_orden":"# Orden",
          "orden_creada":"Es Directa?",
          "fecha_recibio_mercancia":"Fecha Recibió Mercancía",
          "importe":"Importe Mercancía Recibida",
          "factura_folio":"# Factura",
          "factura_folio_sat":"Folio SAT",
          "proveedor.nombre_proveedor":"Proveedor",
          "actions":"Operaciones"
        },
      },
      menu1:false,
      menu2:false,
      sucursales: [],
      search_articulo: '',
      search_marca: null,
      searchProveedor:null,
      categorias: [],
      marcas: [],
      articulos: [],
      proveedores:[],
      articulos_proveedores: [],
      fab: [],
      isLoading: false,
      articulos_encontrados: [],
      id_articulo: "",

      search: "",
      model_filters: "",

      tmp_fecha: "",
      breadcrumbs: [{
          text: "Inicio",
          disabled: false,
          to: "/index"
        },
        {
          text: "Reportes",
          disabled: true,
          href: ""
        },
        {
          text: "Compras por Articulo",
          disabled: true,
          href: ""
        }
      ],
      modal: false,
      modal_articulos: false,
      update: false,
      model: {},
      rules: {
        required: v => !!v || "Este campo es requerido",
        decimal: value => {
          const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
          return pattern.test(value) || "Número decimal no válido";
        },
        entero: function(numero) {
          const pattern = /^[\d]*$/;
          return pattern.test(numero) || "Debe ser un número entero";
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Correo electrónico no valido";
        },
        rfc: v =>
          (v && v.length >= 9 && v.length < 15) || "RFC debe tener al menos 9 caractéres y 14 caractéres"
      },
      items: [],
      registros:[],
    }
  },
  methods: {
    getProveedores: function(el) {
      let data = {
        "selector": {
          "type":"proveedores",
          "estatus": "Activo",
        },
        "fields": [
          "nombre", "_id", "rfc", "plazo", "contacto", "email", "telefono_1"
        ]
      };

      window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
        .then(response => {
          if (response.data.docs.length > 0)
            this.proveedores = response.data.docs;
        })
        .catch(error => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener los proveedores.",
            footer: ""
          });
        });
    },
    upperCaseInput(fieldName) {
      // Guarda la posición actual del cursor
      const input = event.target;
      const start = input.selectionStart;
      const end = input.selectionEnd;

      // Transforma el valor del campo a mayúsculas
      const fieldValue = this.model[fieldName];
      const upperCaseValue = fieldValue.toUpperCase();

      // Actualiza el valor del campo en el modelo de datos
      this.model[fieldName] = upperCaseValue;

      // Actualiza el valor del input en la vista
      input.value = upperCaseValue;

      // Restablece la posición del cursor
      input.setSelectionRange(start, end);
    },
    parse_date(date) {
      if (!date) return null;
      return moment(String(date)).format("YYYY-MM-DD");
    },
    formatNumber: function (x) {
      if (x == undefined) return 0;
      x = x.toString();
      var pattern = /(-?\d+)(\d{3})/;
      while (pattern.test(x)) x = x.replace(pattern, "$1,$2");
      return x;
    },    
    formatNumberDec: function(numero, decimales){
        if (numero == undefined || numero == null || numero == "")
            return "0";
        numero = Number(numero);
        if (isNaN(numero)){
            return "0";
        } else
            return (numero).toFixed(decimales).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    },
    getMonto(row){
        var total = 0;
        if(row.cantidad!=null && row.cantidad!="" && parseFloat(row.cantidad)>0 &&
            row.precio_proveedor!=null && row.precio_proveedor!="" && parseFloat(row.precio_proveedor)>0
        ){
            total = parseFloat(row.cantidad) * parseFloat(row.precio_proveedor)
        }
        return this.formatNumberDec(total.toFixed(4),2);
    },
    parse_date_time(date) {
      if (!date) return null;
      return window.moment(String(date)).format("YYYY-MM-DD HH:mm");
    },
    parse_date_time_mx(date) {
        if (!date) return null;
        return window.moment(String(date)).format("DD-MM-YYYY HH:mm");
    },
    valida_fecha: function(fecha) {
      if (fecha == "Invalid date")
        return "Fecha no válida."
      else
        return true;
    },
    clean_model: function() {
      return {
        id_proveedor: '',
        fecha_de:'',
        fecha_a:'',
        no_orden:'',
        factura:'',
        factura_sat:'',
      };
    },
    consultar: function(){
      if(!this.$refs.form.validate()){return;}
      window.dialogLoader.show('Espere un momento por favor..');
      var where = {
        "type": "compras",        
        "fecha_recibio_mercancia": {
            "$gte": this.model.fecha_de,
            "$lte": this.model.fecha_a+"T23:59:59.999"
        },
        "estatus":"Orden Finalizada",
        "proveedor.id_proveedor":{"$exists":true},
        "no_orden":{"$exists":true},
        "factura_folio":{"$exists":true},
        "factura_folio_sat":{"$exists":true}
      };    

      if(this.model.id_proveedor && this.model.id_proveedor != ""){
        where["proveedor.id_proveedor"] = this.model.id_proveedor;
      }
      if(this.model.no_orden && this.model.no_orden != ""){
        where["no_orden"] = {"$regex": "(?i)" + this.model.no_orden};
      }
      if(this.model.factura && this.model.factura != ""){
        where["factura_folio"] = {"$regex": "(?i)" + this.model.factura};
      }
      if(this.model.factura_sat && this.model.factura_sat != ""){
        where["factura_folio_sat"] = {"$regex": "(?i)" + this.model.factura_sat};
      }

      //console.log("Where: ", where);
      axios({
          method: 'POST',
          url: process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',
          withCredentials: true,
          data: {
            selector: where,            
            use_index: "_design/15d0e050b499a67a432501223cee8c0a0314e5b0"
          },
        }).then(response => {
          if (response.data.docs.length) {                  
            response.data.docs.sort(function (a, b) {
                //Ordenar por fecha más reciente
                return new Date(b.fecha_recibio_mercancia) - new Date(a.fecha_recibio_mercancia);
            });
            this.registros = response.data.docs;  
            for(var k in this.registros){
              if(!this.registros[k].importe){
                this.registros[k]['importe'] = 0;
              }
              for(var ka in this.registros[k].articulos){
                var art = this.registros[k].articulos[ka];
                var importe = parseFloat(art['cantidad_recibida']) * parseFloat(art['precio_proveedor'])
                this.registros[k]['importe'] += importe;
              }
            }
                      
          } else {
            this.registros = [];
          }
        })
        .catch(error => {
          console.log(error)
        }).then(()=>{
          window.dialogLoader.hide();
        })
    },
    verArticulosFactura: function (row) {
            window.dialogLoader.show('Espere un momento por favor..');
            window.axios
            .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + row._id)
            .then(response => {
                row = response.data;
                this.model_vista_factura = row;
                this.modalArticulosFactura = true;
            })
            .catch(error => {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al obtener el registro. Intente nuevamente.",
                    footer: ""
                });
            }).then(()=>{
                window.dialogLoader.hide();
            });
        },
    generar_reporte: function() {
      try {
        if(!this.$refs.form.validate()){return;}
        window.dialogLoader.show('Espere un momento por favor..');

        if (this.model.id_proveedor == null)
          this.model.id_proveedor = '';
        if (this.model.factura == null)
          this.model.factura = '';
        if (this.model.factura_sat == null)
          this.model.factura_sat = '';

        window.axios
            .post(process.env.VUE_APP_REPORTES_URL + "/reportes/rpt_compras/", this.model, {            
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Allow": "POST",
            "cache-control": "no-cache",
            "responseType": "arraybuffer"
          })
          .then(response => {
            var bytes = response.data;
            let blob = new Blob([bytes], {
                type: response.headers["content-type"]
              }),
              url = window.URL.createObjectURL(blob);

            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = url;
            a.download = "ReporteCompras.xlsx";
            a.click();
            a.parentNode.removeChild(a);
          })
          .catch(error => {
            console.log(error);
            this.$swal({
              type: "error",
              title: "¡Operación no Permitida!",
              text: "Ocurrió un error al generar el reporte. Intente nuevamente.",
              footer: ""
            });
          }).then(() => {
            window.dialogLoader.hide();
          });
      } catch (error) {
        console.log("ERRORS: ", error);
      }
    },
    getProveedor: function(id_proveedor){
          var filtro = this.proveedores.find(function(e){
              return e._id == id_proveedor;
          });
          if(filtro){
              return filtro;
          } else {
              return false;
          }
      },
      
     cambiarProveedor(row) {
            window.dialogLoader.show('Espere un momento por favor..');
            window.axios
            .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + row._id)
            .then(response => {
                row = response.data;
                this.model_vista = row;
                this.proveedor_editable = row.proveedor.id_proveedor;
                this.modalProveedor = true;
            })
            .catch(error => {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al obtener el registro. Intente nuevamente.",
                    footer: ""
                });
            }).then(()=>{
                window.dialogLoader.hide();
            });
            
        },


        changeProveedor() {
            try {
                var prove = this.getProveedor(this.proveedor_editable);
                if(prove !== false){
                    var newprove = {
                      "id_proveedor": prove._id,
                      "nombre_proveedor": prove.nombre,
                      "rfc": prove.rfc,
                      "plazo": prove.plazo,
                      "contacto": prove.contacto,
                      "email": prove.email,
                      "telefono_1": prove.telefono_1
                    }                                                      
                      this.$swal({
                          type: "info",
                          title: "Actualizar Proveedor",
                          text: "Los artículos se relacionarán al proveedor seleccionado. ¿Desea Continuar?",
                          footer: "",
                          showCancelButton: true,
                          cancelButtonColor: "#d33",
                          confirmButtonColor: "#3085d6",
                          confirmButtonText: "Si",
                          cancelButtonText: "No"
                      }).then(resultado => {
                          if (resultado.value) {
                              window.dialogLoader.show('Espere un momento por favor..');
                              var self = this;
                              this.model_vista.proveedor = newprove;
                              
                              window.axios
                              .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model_vista._id + '?conflicts=true', this.model_vista)
                              .then(response => {
                                  this.model_vista._rev = response.data.rev;

                                  for(var ka in this.model_vista.articulos){                                                
                                      //Agregar articulo a proveedor
                                      this.asociar_articulo_proveedor(this.model_vista.articulos[ka]["id_articulo"], this.model_vista.proveedor);                                                                                    
                                  }

                                  this.modalProveedor = false;
                                  window.dialogLoader.hide();
                                  this.consultar();                                
                              }).catch(error => {
                                  console.log(error);
                                  this.$swal({
                                      type: "error",
                                      title: "¡Operación no Permitida!",
                                      text: "Ocurrió un error al editar el estatus. Intente nuevamente.",
                                      footer: ""
                                  });
                              });
                          } else {
                              this.modalProveedor = false;
                              window.dialogLoader.hide();
                              this.consultar();                            
                          }
                      });

                }
                
                
                

            } catch (error) {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al editar el registro. Intente nuevamente.",
                    footer: ""
                });
                console.log(error);
                window.dialogLoader.hide();
            }

        },

        asociar_articulo_proveedor: function(id_articulo, proveedor){
            try {
                let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/_design/funciones_articulos/_update/agregar_proveedor/" + id_articulo;
                let data = {
                    id_proveedor: proveedor.id_proveedor,
                    nombre_proveedor: proveedor.nombre_proveedor,
                };

                window.axios
                    .post(url, data)
                    .then(response => {
                        if (response.data == true) {
                            //console.log("Articulo: "+id_articulo+" Agregado a proveedor correctamente");                                                
                        } else {
                            //console.log("Error Articulo: "+id_articulo+" no se agregó a proveedor");
                        }
                    }).catch(error => {
                        console.log("Exception Articulo: "+id_articulo+": "+error);
                    });
            } catch (error) {
                console.log("Error en asociar_articulo_proveedor: ", error);
            }
        },



  }
}
</script>
